<template>
  <div class="mt-5">
    <v-card style="height: 80vh">
      <v-card-title>{{
        this.applicationList[0].candidate.full_name +
        " for " +
        this.applicationList[0].job_post.title
      }}</v-card-title>
      <v-card-text>
        <div style="height: 30vh">
          <div style="float: left; overflow-y: auto">
            <ol>
              <li
                style="width: 500px; margin-bottom: 20px; cursor: pointer"
                v-for="(item, i) in formatedList"
                :key="i"
                v-on:click="getVideoUrl(item)"
              >
                <p>{{ item.title }}</p>
              </li>
            </ol>
          </div>
          <video
            :key="videoAnswerUrl"
            width="550"
            style="float: right; max-height: 85%"
            controls
          >
            <source :src="videoAnswerUrl" type="video/mp4" />
          </video>
        </div>
        <v-divider></v-divider>
        <div>
          <v-card-title style="justify-content: center"
            >Candidate Feedback</v-card-title
          >
          <div class="rating" style="text-align: center; margin-bottom: 10px">
            <v-rating
              readonly
              v-model="this.ratingCount"
              background-color="orange lighten-3"
              color="orange"
              :value="this.ratingCount"
              medium
            ></v-rating>
          </div>
          <div
            style="text-align: center; align-items: center; align-self: center"
          >
            <v-textarea
              solo
              readonly
              style="width: 30%; align-items: center; margin: auto"
              name="input-7-4"
              :label="remarks"
            ></v-textarea>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import { UTC_TO_LOCAL } from "@/helpers";
import { mapMutations, mapState } from "vuex";

export default {
  name: "candidate-info",
  data() {
    return {
      applicationList: [],
      job: {},
      loading: false,
      videoAnswerUrl: "",
      ratingCount: "",
      remarks: "",
    };
  },
  computed: {
    ...mapState(["auto_interviews"]),
    headers() {
      return [
        {
          text: this.$vuetify.lang.t("$vuetify.title"),
          value: "title",
        },
        { text: this.$vuetify.lang.t("$vuetify.question_type"), value: "type" },
        {
          text: this.$vuetify.lang.t("$vuetify.reading_time"),
          value: "reading_time",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.answer_time"),
          value: "answer_time",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.screen_share"),
          value: "screen_share",
        },
        { text: this.$vuetify.lang.t("$vuetify.order_no"), value: "order_no" },
        {
          text: this.$vuetify.lang.t("$vuetify.answer_url"),
          value: "answer_url",
        },
      ];
    },
    formatedList() {
      let list =
        this.applicationList &&
        this.applicationList[0].applicant_interview &&
        this.applicationList[0].applicant_interview.map((application) => {
          return {
            id: application._id,
            title: application.question && application.question.title,
            type: application.question && application.question.question_type,
            reading_time:
              application.question && application.question.reading_time,
            answer_time:
              application.question && application.question.time_duration,
            screen_share:
              application.question && application.question.screen_share,
            order_no: application.question && application.question.order_no,
            answer_url: application.answer_url,
          };
        });
      return list;
    },
  },
  methods: {
    ...mapMutations(["SET_PROFILE_LINKS"]),

    async fetchList() {
      let interviewId = this.$router.history.current.params.id;
      this.applicationList = this.auto_interviews.filter(
        (interview) => interview._id === interviewId
      );
      this.loading = false;
      this.ratingCount =
        this.applicationList[0].applicant_interview_feedback[0] &&
        this.applicationList[0].applicant_interview_feedback[0].rating;
      this.remarks =
        this.applicationList[0].applicant_interview_feedback[0] &&
        this.applicationList[0].applicant_interview_feedback[0].remarks;
    },
    getVideoUrl(question) {
      this.videoAnswerUrl = question.answer_url;
    },
    goToProfile(item) {
      const links = [...this.profileLinks];
      const index = links.findIndex((profile) => profile.id === item.id);
      if (index === -1) links.push(item);
      this.SET_PROFILE_LINKS(links);
    },
  },
  created() {
    this.fetchList();
    this.videoAnswerUrl = this.formatedList[0].answer_url;
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
}
.tableSearch {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  min-height: 70px;
}
.table-row {
  background-color: #f7f7f7;
  border: 1px solid #e6e6f1;
  td {
    color: #2f3242;
  }
}
</style>
